import React, { useCallback } from 'react';

import { LifestyleNanoID } from '../../../lifestylesTypes';
import { ClassName, GADataType, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { copyToClipboard } from '../../../../../utils/copyToClipboard';

import { LifestylePath } from '../../../LifestylePath';
import { TeamPath } from '../../../../teams/TeamPath';

import { words } from '../../../../../locales/keys';

interface LifestyleCopyLinkButtonProps {
  companyNanoId?: TeamNanoID;
  isMyLibrary?: boolean;
  lifestyleNanoId: LifestyleNanoID;
  icon?: IconsEnum;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  className?: ClassName;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
  disabled?: boolean;
  onClick?: () => void;
}

function LifestyleCopyLinkButton({
  companyNanoId,
  isMyLibrary,
  lifestyleNanoId,
  className,
  i18nText,
  i18nTextClassName,
  icon,
  iconClassName,
  tooltipI18nText,
  disabled,
  dataGa,
  onClick
}: LifestyleCopyLinkButtonProps & GADataType) {
  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: words.linkCopied
  });

  const handleCopyLifestyleUrl = useCallback(() => {
    let path = LifestylePath.show(lifestyleNanoId);

    if (isMyLibrary) {
      path = TeamPath.currentCompanyLibraryLifestyle(lifestyleNanoId);
    } else if (companyNanoId) {
      path = TeamPath.companyLibraryLifestyle(companyNanoId, lifestyleNanoId);
    }

    copyToClipboard(`${window.location.origin}${path}`);
    onClick?.();
    showToastI18nNotification();
  }, [
    isMyLibrary,
    lifestyleNanoId,
    onClick,
    showToastI18nNotification,
    companyNanoId
  ]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      dataGa={dataGa || 'lifestyles-lifestyle-copy-link-button'}
      icon={icon}
      tooltipI18nText={tooltipI18nText}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleCopyLifestyleUrl}
      iconClassName={iconClassName}
      disabled={disabled}
    />
  );
}

export default LifestyleCopyLinkButton;
