import { gql } from 'graphql-request';
import {
  LifestyleCategoryName,
  LifestyleCategoryUUID,
  LifestyleCreatedAt,
  LifestyleBlocked,
  LifestyleFavorite,
  LifestyleID,
  LifestyleName,
  LifestyleNda,
  LifestyleUUID,
  LifestyleImageUUID,
  LifestyleImageFile,
  LifestyleCategoryLocalizedName,
  LifestyleNanoID,
  LifestyleRenderTypeID,
  LifestyleRenderTypeName,
  LifestylePremiumContentAt,
  LifestyleAuthorID,
  LifestyleAuthorLocalizedName,
  LifestyleImageID
} from '../lifestylesTypes';

export interface FetchLifestylesQueryResponse {
  author: {
    id: LifestyleAuthorID;
    localizedName: LifestyleAuthorLocalizedName;
  };
  blocked: LifestyleBlocked;
  favorite: LifestyleFavorite;
  id: LifestyleID;
  uuid: LifestyleUUID;
  nanoId: LifestyleNanoID;
  name: LifestyleName;
  nda: LifestyleNda;
  createdAt: LifestyleCreatedAt;
  updatedAt: LifestyleCreatedAt;
  premiumContentAt: LifestylePremiumContentAt | null;
  maxFile: {
    createdAt: LifestyleCreatedAt;
  };
  renderTypes: {
    id: LifestyleRenderTypeID;
    name: LifestyleRenderTypeName;
  }[];
  image: {
    id: LifestyleImageID;
    uuid: LifestyleImageUUID;
    file: LifestyleImageFile;
  };
  category: {
    uuid: LifestyleCategoryUUID;
    name: LifestyleCategoryName;
    localizedName: LifestyleCategoryLocalizedName;
  };
}

export const FETCH_LIFESTYLES_QUERY = gql`
  query Lifestyles(
    $filters: LifestylesFilters
    $sort: [LifestylesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    lifestyles(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        author {
          id
          localizedName
        }
        blocked
        favorite
        id
        uuid
        name
        nanoId
        nda
        createdAt
        updatedAt
        premiumContentAt
        maxFile {
          createdAt
        }
        renderTypes {
          id
          name
        }
        image {
          id
          uuid
          file
        }
        category {
          uuid
          name
          localizedName
        }
      }
      paginationInfo {
        currentPage
        nextPage
        totalCount
      }
      sql
    }
  }
`;
